import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import Switch from "../../ReactHookForm/Switch";
import { validationRules } from "../../../constants/validationRules";
import Select from "../../ReactHookForm/Select";
import {
  Modal,
  Box,
  MenuItem,
  Grid,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { sassEndpoints } from "../../../constants/endpoints";
import axios from "axios";
import { stringFormat } from "../../../utils/stringHelpers";
import { regexPatterns } from "../../../constants/regexPatterns";
import { useEffect, useState } from "react";
import "../../../assets/v2/main.scss";
import { useTheme } from "@emotion/react";
import "./terminal.scss";

const CreateTerminalModel = ({
  create,
  terminalTypes,
  setCreate,
  setShowBackdrop,
  showSnackbar,
  gatewayInfo,
  processors,
  setRefreshData,
}) => {
  const methods = useForm();
  const [processorId, setProcessorId] = useState(null);
  const [createError, setCreateError] = useState(false);
  const [hasPrinter, setHasPrinter] = useState(false);
  const theme = useTheme();
  const tipEnabled = methods.watch("tipEnabled");

  useEffect(() => {
    if (!create) {
      methods.reset();
      setCreateError(false);
    }
  }, [create]);

  useEffect(() => {
    if (tipEnabled) {
      // Set default values for tip preferences when tip is enabled
      methods.setValue("tipPreference1", 10);
      methods.setValue("tipPreference2", 15);
      methods.setValue("tipPreference3", 20);
    } else {
      // reset the tip preferences if tip is disabled
      methods.setValue("tipPreference1", null);
      methods.setValue("tipPreference2", null);
      methods.setValue("tipPreference3", null);
    }
  }, [tipEnabled]);

  const createTerminal = () => {
    setShowBackdrop(true);

    const tipPreference = {};
    if (methods.getValues().tipPreference1) {
      tipPreference.tipPreference1 = parseFloat(
        methods.getValues().tipPreference1 / 100,
      );
    }
    if (methods.getValues().tipPreference2) {
      tipPreference.tipPreference2 = parseFloat(
        methods.getValues().tipPreference2 / 100,
      );
    }
    if (methods.getValues().tipPreference3) {
      tipPreference.tipPreference3 = parseFloat(
        methods.getValues().tipPreference3 / 100,
      );
    }
    const payload = {
      name: methods.getValues().terminal_name,
      sourceTerminalId: methods.getValues().terminal_id,
      terminalTypeId: methods.getValues().model,
      serialNumber: methods.getValues().serial_number,
      activateOnCreation: methods.getValues().activate,
      tipEnabled: methods.getValues().tipEnabled,
      receiptTimeoutDelay:
        parseInt(methods.getValues().receipt_timeout_delay) * 1000, //SaaS accepts milliseconds
      printingEnabled: methods.getValues().printing_enabled ?? false,
      tipPreference:
        Object.keys(tipPreference).length > 0 ? tipPreference : undefined, // Only include if not empty
    };
    let url = stringFormat(sassEndpoints.terminal.postTerminal, [
      gatewayInfo.gatewayId,
      processorId,
    ]);
    axios
      .post(url, payload)
      .then(() => {
        showSnackbar("Successfully added the terminal");
        setCreate(false);
        setRefreshData(true);
      })
      .catch(() => {
        setCreateError(true);
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const handleSelected = (e, field) => {
    methods.setValue(field, e);
    setHasPrinter(
      terminalTypes.find(
        (type) => type.terminalTypeId === methods.getValues().model,
      )?.hasPrinter ?? false,
    );
  };
  return (
    <FormProvider {...methods}>
      <Modal
        open={create}
        aria-labelledby="add-new-terminal"
        className={theme.palette.mode === "dark" ? "dark-theme" : ""}
      >
        <Box className="v2 create-terminal-modal">
          <Grid
            container
            flexDirection={"column"}
            justifyContent="space-between"
            style={{ height: "100%" }}
            rowSpacing={2}
          >
            <Grid item>Add New Terminal</Grid>
            <Grid item>
              Please make sure that terminal is powered on and connected to the
              internet.
            </Grid>
            <Grid item>
              <RegisteredTextField
                name="terminal_name"
                label="Name"
                fullWidth
                placeholder="Friendly name for the terminal"
                rules={{
                  required:
                    "Please enter a friendly terminal name under 50 characters.",

                  maxLength: validationRules.maxLength50,
                }}
              >
                Name
              </RegisteredTextField>
            </Grid>
            <Grid item>
              <RegisteredTextField
                name="serial_number"
                label="Serial Number"
                fullWidth
                placeholder="Scan or input the terminal’s serial number."
                rules={{
                  required:
                    "Please enter a serial number under 255 characters.",
                  maxLength: validationRules.maxLength255,
                }}
              >
                Serial Number
              </RegisteredTextField>
            </Grid>
            <Grid item>
              <RegisteredTextField
                name="terminal_id"
                label="Terminal ID"
                fullWidth
                placeholder="Fiserv terminal Id"
                rules={{
                  pattern: {
                    value: regexPatterns.numeric,
                    message: "Terminal ID may only contain numbers",
                  },
                  required: "Please enter a TID between 8 to 50 characters.",
                  maxLength: validationRules.maxLength50,
                  minLength: {
                    value: 8,
                    message: "Please enter a TID between 8 to 50 characters.",
                  },
                }}
              >
                Terminal ID
              </RegisteredTextField>
            </Grid>
            <Grid item>
              <RegisteredTextField
                name="receipt_timeout_delay"
                label="Receipt Timeout Delay (s)"
                fullWidth
                placeholder="Receipt timeout delay in seconds"
                defaultValue="30"
                rules={{
                  pattern: {
                    value: regexPatterns.numericNoLeadingZero,
                    message:
                      "Receipt timeout delay may only contain a number greater than 0",
                  },
                  required:
                    "Please enter a receipt timeout delay between 1 and 999",
                  maxLength: validationRules.maxlength3,
                  minLength: {
                    value: 1,
                    message:
                      "Please enter a receipt timeout delay between 1 and 999",
                  },
                }}
              >
                Receipt Timeout Delay
              </RegisteredTextField>
            </Grid>
            <Grid item>
              <Select
                control={methods.control}
                name="model"
                label="Model"
                fullWidth
                placeholder="Select a model"
                {...methods.register("model", { required: true })}
                onChange={(e) => handleSelected(e, "model")}
                rules={{
                  required: "Please select a model.",
                }}
              >
                {terminalTypes.map((terminal, i) => (
                  <MenuItem key={i} value={terminal.terminalTypeId}>
                    {terminal.model}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Select
                control={methods.control}
                name="processor"
                label="Processor"
                fullWidth
                placeholder="Select a processor"
                {...methods.register("processor", { required: true })}
                onChange={(e) => {
                  setProcessorId(e);
                }}
                rules={{
                  required: "Please select a processor.",
                }}
              >
                {processors.map((p, i) => (
                  <MenuItem key={i} value={p.processorId}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Switch
                name="activate"
                label="Activate on create"
                defaultValue="true"
              >
                Activate on create
              </Switch>
            </Grid>
            <Grid item>
              <Switch
                name="tipEnabled"
                label="Tip enabled"
                defaultValue={false}
                defaultChecked={false}
              >
                Tip enabled
              </Switch>
            </Grid>
            <Grid item>
              {tipEnabled && (
                <>
                  <Typography>Tip Preferences</Typography>
                  <Grid container spacing={2} justifyContent="flex-start">
                    {[1, 2, 3].map((tipNumber) => {
                      const defaultTipValues = { 1: 10, 2: 15, 3: 20 }; // Default values for each tip preference
                      return (
                        <Grid item xs={2.3} key={tipNumber}>
                          <TextField
                            name={`tipPreference${tipNumber}`}
                            label=""
                            type="number"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: 0,
                                max: 100,
                                step: 1,
                                style: {
                                  MozAppearance: "textfield",
                                },
                                onInput: (e) => {
                                  // Restrict input value to be between 0 and 100
                                  if (e.target.value > 100) {
                                    e.target.value = 100;
                                  }
                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                  }
                                },
                              },
                              inputMode: "numeric",
                            }}
                            sx={{
                              "& input[type=number]": {
                                "-moz-appearance": "textfield",
                              },
                              "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                                {
                                  "-webkit-appearance": "none",
                                  margin: 0,
                                },
                            }}
                            defaultValue={defaultTipValues[tipNumber]}
                            onChange={(e) =>
                              methods.setValue(
                                `tipPreference${tipNumber}`,
                                e.target.value,
                              )
                            }
                            rules={{
                              required: `Please enter Tip Preference ${tipNumber}`,
                              validate: (value) =>
                                (value >= 0 && value <= 100) ||
                                `Tip Preference ${tipNumber} must be between 0 and 100`,
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item>
              {hasPrinter && (
                <Switch
                  name="printing_enabled"
                  label="Printing enabled"
                  defaultValue={true}
                  defaultChecked={true}
                >
                  Printing enabled
                </Switch>
              )}
            </Grid>
            <Grid item>
              {createError && (
                <div className="caption create-error-msg">
                  An error has occurred while processing your request, please
                  try again!
                </div>
              )}
            </Grid>
            <Grid item>
              {" "}
              <div className="btn-grp">
                <Button
                  className="btn--secondary"
                  onClick={() => {
                    setCreate(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn--primary"
                  style={{ marginLeft: "10px" }}
                  onClick={async () => {
                    const noErrors = await methods.trigger();
                    if (
                      noErrors &&
                      methods.getValues().serial_number.length > 0 &&
                      methods.getValues().terminal_id.length > 0 &&
                      methods.getValues().terminal_name.length > 0
                    ) {
                      createTerminal();
                    }
                  }}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </FormProvider>
  );
};

CreateTerminalModel.propTypes = {
  create: PropTypes.boolean,
  terminalTypes: PropTypes.any,
  setCreate: PropTypes.func,
  setShowBackdrop: PropTypes.func,
  showSnackbar: PropTypes.func,
  gatewayInfo: PropTypes.any,
  processors: PropTypes.any,
  setRefreshData: PropTypes.func,
};

export default CreateTerminalModel;
