import PropTypes from "prop-types";
import { merchantRoutes } from "../../../constants/routes";
import {
  currencyInputOnKeyDown,
  currencyInputOnChange,
} from "./transactionHelpers";
import ActionButton from "../../Buttons/ActionButton";
import { useForm } from "react-hook-form";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { sassEndpoints } from "../../../constants/endpoints";
import axios from "axios";
import { stringFormat } from "../../../utils/stringHelpers";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import IncreaseAuthForm from "./IncreaseAuthForm";

const SearchResultsActions = ({
  transactionData,
  userSettings,
  refundTransaction,
  captureTransaction,
  voidTransaction,
  vaultTransaction,
  emailTransaction,
  incrementAuth,
  handleChangePromptDialogProps,
  showAlertDialogError,
}) => {
  const history = useHistory();
  const { register, handleSubmit } = useForm({
    shouldUnregister: true,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [increaseAmount, setIncreaseAmount] = React.useState(0);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkPermissions = () => {
    const canCapture =
      transactionData.status === "Authorized" &&
      userSettings?.gatewayUserPermissions?.PROCESS_CAPTURE;

    const canVoid =
      (transactionData.status === "PendingSettlement" ||
        transactionData.status === "Authorized") &&
      userSettings?.gatewayUserPermissions?.PROCESS_VOID &&
      transactionData.transactionSource !== "PhysicalTerminal";

    const canRefund =
      (transactionData.status === "Settled" ||
        transactionData.status === "PartiallyRefunded") &&
      (transactionData.type === "Sale" ||
        transactionData.type === "Authorize") &&
      userSettings?.gatewayUserPermissions?.PROCESS_REFUND &&
      transactionData.transactionSource !== "PhysicalTerminal";

    const canVault =
      !transactionData.customer?.customerId &&
      !transactionData.customerId &&
      transactionData.status !== "Declined" &&
      transactionData.status !== "Unknown" &&
      userSettings?.gatewayUserPermissions["VAULT_CREATE"];

    return [canCapture, canVoid, canRefund, canVault].filter(Boolean).length;
  };

  const actionCount = checkPermissions();

  const onSubmitEmail = async (data) => {
    //Handle Email Send
    emailTransaction(data.transactionId, data.email_addresses);
  };
  const actions = [
    { action: "transaction_info", title: "Transaction Info" },
    { action: "transaction_capture", title: "Capture" },
    { action: "transaction_void", title: "Void Transaction" },
    { action: "transaction_refund", title: "Refund Transaction" },
    { action: "transaction_email", title: "Email" },
    { action: "transaction_vault", title: "Vault" },
    { action: "transaction_increment_auth", title: "Increment Auth" },
  ];

  const onSubmitCaptureRefund = async (data) => {
    //Handle Refund Process
    if (data.commandType === "refund") {
      let normalizedAmount = Number(data.amount) || 0;
      let remainingAmount =
        transactionData.amountCaptured - transactionData.amountRefunded;
      if (normalizedAmount === 0) {
        showAlertDialogError("Amount must be greater than 0.00");
        return;
      }

      if (normalizedAmount > remainingAmount) {
        showAlertDialogError(
          "Amount cannot be greater than " + remainingAmount.toFixed(2),
        );
        return;
      }

      if (transactionData.invoiceId && normalizedAmount < remainingAmount) {
        showAlertDialogError(
          "Invoice transactions cannot be partially refunded",
        );
        return;
      }
      refundTransaction(data.transactionId, normalizedAmount);
    }

    //Handle Capture Process
    if (data.commandType === "capture") {
      let normalizedAmount = Number(data.amount) || 0;

      if (normalizedAmount === 0) {
        showAlertDialogError("Amount must be greater than 0.00");
        return;
      }

      if (normalizedAmount > transactionData.amountAuthorized) {
        showAlertDialogError(
          "Amount cannot be greater than " +
            transactionData.amountAuthorized.toFixed(2),
        );
        return;
      }
      captureTransaction(data.transactionId, normalizedAmount);
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  const handleTransactionDetailNavigation = (id) => {
    let url = stringFormat(sassEndpoints.transactions.byId, [
      userSettings.gatewayId,
      id,
    ]);
    axios
      .get(url)
      .then((response) => {
        history.push({
          pathname: `${merchantRoutes.reporting.transaction_detail}/${response.data.data.transactionId}`,
        });
      })
      .catch(() => {
        console.log("Error retrieving transaction details!");
      });
  };

  const handleCaptureRefundClick = (id, eventType) => {
    handleChangePromptDialogProps(
      {
        alertTitle:
          (eventType === "capture" ? "Capture" : "Refund") + " Transaction",
        contentElement: renderCaptureRefundCommand(id, eventType),
        actionButtons: [
          {
            text: "Process",
            color: "primary",
            onclick: handleSubmit(onSubmitCaptureRefund, onError),
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: handleClosePromptDialog,
      },
      true,
    );
  };

  const handleEmailTransactionClick = (id) => {
    handleChangePromptDialogProps(
      {
        alertTitle: "Email Receipt",
        contentElement: renderEmailCommand(id),
        actionButtons: [
          {
            text: "Send",
            color: "primary",
            onclick: handleSubmit(onSubmitEmail, onError),
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: handleClosePromptDialog,
      },
      true,
    );
  };

  const handleIncrementAuthButtonOnClick = (transactionData) => {
    handleChangePromptDialogProps(
      {
        alertTitle: "Increment Auth",

        contentElement: (
          <IncreaseAuthForm
            transactionData={transactionData}
            increaseAmount={increaseAmount}
            setIncreaseAmount={setIncreaseAmount}
          />
        ),

        actionButtons: [
          {
            text: "Process",
            color: "primary",
            onclick: () =>
              incrementAuth(
                transactionData.transactionId,
                increaseAmount / 100,
              ),
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: handleClosePromptDialog,
      },
      true,
    );
  };

  const handleVoidClick = (id) => {
    handleChangePromptDialogProps(
      {
        alertTitle: "Void Transaction",
        actionButtons: [
          {
            text: "Process",
            color: "primary",
            onclick: () => voidTransaction(id),
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: handleClosePromptDialog,
      },
      true,
    );
  };

  const handleVaultClick = (id) => {
    handleChangePromptDialogProps(
      {
        alertTitle: "Vault Transaction",
        actionButtons: [
          {
            text: "Process",
            color: "primary",
            onclick: () => vaultTransaction(id),
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: handleClosePromptDialog,
      },
      true,
    );
  };

  const handleClosePromptDialog = () => {
    handleChangePromptDialogProps({}, false);
  };

  const renderEmailCommand = (id) => {
    return (
      <form onSubmit={handleSubmit(onSubmitEmail, onError)}>
        <input type="hidden" value={id} {...register("transactionId")} />
        <TextField
          autoFocus
          fullWidth
          autoComplete="off"
          size="small"
          fontSize="12"
          placeholder="email@example.com, email2@example.com"
          FormHelperTextProps={{
            sx: { ml: 0 },
          }}
          inputProps={{
            type: "text",
            maxLength: 500,
            style: { fontSize: 12 },
          }}
          defaultValue=""
          {...register("email_addresses")}
        />
      </form>
    );
  };

  const renderCaptureRefundCommand = (id, commandType) => {
    let defaultAmount = 0;
    if (commandType === "capture") {
      defaultAmount = transactionData.amountAuthorized.toFixed(2);
    } else if (commandType === "refund") {
      defaultAmount = (
        transactionData.amountCaptured - transactionData.amountRefunded
      ).toFixed(2);
    }

    return (
      <>
        <form onSubmit={handleSubmit(onSubmitCaptureRefund, onError)}>
          <input type="hidden" value={id} {...register("transactionId")} />
          <input
            type="hidden"
            value={commandType}
            {...register("commandType")}
          />
          <FormControl
            size="small"
            sx={{ margin: 0.5, width: 175 }}
            onChange={currencyInputOnChange}
            onKeyDown={currencyInputOnKeyDown}
          >
            <OutlinedInput
              autoFocus
              autoComplete="off"
              inputProps={{
                type: "text",
                maxLength: 12,
                size: "small",
              }}
              defaultValue={defaultAmount}
              {...register("amount")}
              startAdornment="$"
            />
          </FormControl>
        </form>
      </>
    );
  };

  const renderActionsAsMenu = () => (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {actions.map((item) => (
          <ActionButton
            key={item.action}
            action={item.action}
            userSettings={userSettings}
            data={transactionData}
            renderType={"menu"}
            onClick={() => {
              // Call the appropriate handler based on action type
              switch (item.action) {
                case "transaction_info":
                  handleTransactionDetailNavigation(
                    transactionData.transactionId,
                  );
                  break;
                case "transaction_capture":
                  handleCaptureRefundClick(
                    transactionData.transactionId,
                    "capture",
                  );
                  break;
                case "transaction_void":
                  handleVoidClick(transactionData.transactionId);
                  break;
                case "transaction_refund":
                  handleCaptureRefundClick(
                    transactionData.transactionId,
                    "refund",
                  );
                  break;
                case "transaction_email":
                  handleEmailTransactionClick(transactionData.transactionId);
                  break;
                case "transaction_vault":
                  handleVaultClick(transactionData.transactionId);
                  break;
                default:
                  break;
                case "transaction_increment_auth":
                  handleIncrementAuthButtonOnClick(transactionData);
                  break;
              }
              handleClose();
            }}
          />
        ))}
      </Menu>
    </div>
  );

  const renderActionsDirectly = () => (
    <ButtonGroup>
      {actions.map((item) => (
        <Tooltip key={item.action} title={item.title}>
          <div>
            <ActionButton
              action={item.action}
              userSettings={userSettings}
              data={transactionData}
              onClick={() => {
                // Call the appropriate handler based on action type
                switch (item.action) {
                  case "transaction_info":
                    handleTransactionDetailNavigation(
                      transactionData.transactionId,
                    );
                    break;
                  case "transaction_capture":
                    handleCaptureRefundClick(
                      transactionData.transactionId,
                      "capture",
                    );
                    break;
                  case "transaction_void":
                    handleVoidClick(transactionData.transactionId);
                    break;
                  case "transaction_refund":
                    handleCaptureRefundClick(
                      transactionData.transactionId,
                      "refund",
                    );
                    break;
                  case "transaction_email":
                    handleEmailTransactionClick(transactionData.transactionId);
                    break;
                  case "transaction_vault":
                    handleVaultClick(transactionData.transactionId);
                    break;
                  default:
                    break;
                }
              }}
            />
          </div>
        </Tooltip>
      ))}
    </ButtonGroup>
  );

  return actionCount > 1 ? renderActionsAsMenu() : renderActionsDirectly();
};

SearchResultsActions.propTypes = {
  transactionData: PropTypes.object,
  userSettings: PropTypes.object,
  alertDialogOpen: PropTypes.bool,
  voidTransaction: PropTypes.func,
  refundTransaction: PropTypes.func,
  captureTransaction: PropTypes.func,
  vaultTransaction: PropTypes.func,
  emailTransaction: PropTypes.func,
  incrementAuth: PropTypes.func.isRequired,
  handleChangeAlertDialogProps: PropTypes.func,
  handleChangePromptDialogProps: PropTypes.func,
  showAlertDialogError: PropTypes.func,
};

export default SearchResultsActions;
