import { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { stringFormat } from "../../../utils/stringHelpers";
import { sassEndpoints } from "../../../constants/endpoints";
import emptyState from "../../../assets/images/empty-state.png";
import "../../../assets/v2/main.scss";
import "./terminal.scss";
import SearchList from "../../Search/SearchList/SearchList";
import { Button, CircularProgress, Grid } from "@mui/material";

import CreateTerminalModel from "./CreateTerminalModel";
import TerminalDetails from "./TerminalDetails";

const TerminalContainer = ({
  activeTab,
  showError,
  showSnackbar,
  gatewayInfo,
  setShowBackdrop,
  setAlertDialogProps,
  setAlertDialogOpen,
}) => {
  const [terminalData, setTerminalData] = useState(null);
  const [resultOffset, setResultOffset] = useState(0);
  const [create, setCreate] = useState(false);
  const [terminalTypes, setTerminalTypes] = useState(null);
  const [processors, setProcessors] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [activeTerminalObj, setActiveTerminalObj] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [loadingDetails, setLoadingDetails] = useState(false);

  useEffect(() => {
    if (create) {
      if (processors !== null && processors.length === 0) {
        showError(
          "A physical terminal processor must be created before any terminals.",
        );
        setCreate(false);
      } else if (terminalTypes !== null && terminalTypes.length === 0) {
        showError("Unable to create terminal");
        setCreate(false);
      } else {
        setShowBackdrop(true);
        axios
          .get(sassEndpoints.terminal.terminalType)
          .then((r) => {
            if (r.status === 204) {
              showError("Unable to create terminal");
              setTerminalTypes([]);
              setCreate(false);
              setShowBackdrop(false);
            } else {
              setTerminalTypes(r.data.data);
              getProcessorTypes();
            }
          })
          .catch(() => {
            showError("Failed to get model types");
            setCreate(false);
            setShowBackdrop(false);
          });
      }
    }
  }, [create]);

  useEffect(() => {
    if (activeTab === 4) {
      getTerminalData();
    }
  }, [activeTab]);

  useEffect(() => {
    if (refreshData) {
      getTerminalData(true);
      setRefreshData(false);
    }
  }, [refreshData]);

  const updateConfigs = () => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.terminal.updateConfigs, [
      gatewayInfo.gatewayId,
    ]);
    axios
      .put(url)
      .then(() => {
        setAlertDialogProps({});
        setAlertDialogOpen(false);
        getTerminalData(true);
        showSnackbar(
          "Successfully updated terminals' software and configuration.",
        );
      })
      .catch(function (error) {
        showError(
          error?.response?.data?.statusDetails?.join(", ") ||
            "Failed to update terminals' software and configuration.",
        );
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const getTerminalData = (refresh = false, terminalId) => {
    setShowBackdrop(true);
    let url;
    if (refresh) {
      url =
        stringFormat(sassEndpoints.gateways.terminal, [
          gatewayInfo?.gatewayId,
        ]) + `?offset=0&limit=100`;
    } else {
      url =
        stringFormat(sassEndpoints.gateways.terminal, [
          gatewayInfo?.gatewayId,
        ]) + `?offset=${resultOffset}&limit=100`;
    }

    axios
      .get(url)
      .then((response) => {
        if (response.status === 204) {
          setTerminalData([]);
        } else {
          let responseResults = response?.data?.data?.results;
          setTerminalData(responseResults);
          setResultsTotal(response?.data?.data?.rowCount);
          if (refresh && !terminalId) {
            setResultOffset(100);
            setActiveIndex(0);
            getTerminalDetails(responseResults[0]);
          } else if (refresh && terminalId) {
            let foundIndex;
            responseResults.find((t, i) => {
              if (t.terminalId === terminalId) {
                foundIndex = i;
              }
            });
            setActiveIndex(foundIndex);
            getTerminalDetails(responseResults[foundIndex]);
          } else {
            getTerminalDetails(responseResults[activeIndex]);
            setResultOffset(resultOffset + 100);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        showError("Failed to get terminal data");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  useEffect(() => {
    if (terminalData) {
      getTerminalDetails();
    }
  }, [activeIndex]);

  const getTerminalDetails = (terminalObj) => {
    setLoadingDetails(true);
    let url;
    if (terminalObj) {
      url = stringFormat(sassEndpoints.terminal.terminalId, [
        gatewayInfo?.gatewayId,
        terminalObj?.processor?.processorId,
        terminalObj?.terminalId,
      ]);
    } else {
      url = stringFormat(sassEndpoints.terminal.terminalId, [
        gatewayInfo?.gatewayId,
        terminalData[activeIndex]?.processor?.processorId,
        terminalData[activeIndex]?.terminalId,
      ]);
    }

    axios
      .get(url)
      .then((response) => {
        setActiveTerminalObj(response.data.data);
      })
      .catch(() => {
        showError("Error loading terminal details");
      })
      .finally(() => {
        setLoadingDetails(false);
      });
  };

  const getProcessorTypes = () => {
    const url = stringFormat(sassEndpoints.processors.processor, [
      gatewayInfo?.gatewayId,
    ]);
    axios
      .get(url)
      .then((response) => {
        if (response.status === 204) {
          showError(
            "A physical terminal processor must be created before any terminals.",
          );
          setCreate(false);
          setShowBackdrop(false);
        } else {
          let filteredProcessors = response?.data?.data?.filter(
            (p) =>
              p?.processorType?.certificationCategory === "PhysicalTerminal",
          );
          if (filteredProcessors?.length > 0) {
            setProcessors(filteredProcessors);
          } else {
            showError(
              "A physical terminal processor must be created before any terminals.",
            );
            setProcessors([]);
            setCreate(false);
          }
        }
        setShowBackdrop(false);
      })
      .catch(() => {
        showError("Failed to get model types");
        setCreate(false);
        setShowBackdrop(false);
      });
  };

  const terminalCard = (terminal) => (
    <Grid container flexDirection={"row"} justifyContent={"space-between"}>
      <Grid container flexDirection={"column"} xs={7}>
        <Grid item className="h5" style={{ wordBreak: "break-word" }}>
          {terminal?.name}
        </Grid>
        {
          <Grid item>
            <div
              className={
                terminal?.status?.toLowerCase() === "active"
                  ? "mobile-status tag sm tag__success"
                  : terminal?.status?.toLowerCase() === "pending"
                    ? "mobile-status tag sm tag__warning"
                    : "mobile-status tag sm tag__failed"
              }
            >
              {terminal?.status?.toUpperCase()}
            </div>
          </Grid>
        }

        <Grid item className="caption">
          Model Name: {terminal?.terminalType?.model}
        </Grid>
        <Grid item className="caption">
          SN: {terminal?.serialNumber}
        </Grid>
      </Grid>
      <Grid item>
        <div
          xs={4}
          className={
            terminal?.status?.toLowerCase() === "active"
              ? "big-screen tag sm tag__success"
              : terminal?.status?.toLowerCase() === "pending"
                ? "big-screen tag sm tag__warning"
                : "big-screen tag sm tag__failed"
          }
        >
          {terminal?.status?.toUpperCase()}
        </div>
      </Grid>
    </Grid>
  );

  return (
    <>
      <div className={"v2"}>
        <Grid container flexDirection={"column"} rowSpacing={2}>
          <Grid item>
            <Grid
              container
              flexDirection={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              className="create-div"
            >
              <Grid item xs={8.7}>
                This section is dedicated to managing physical terminals. Here,
                you can add, modify, or take action on existing terminals to
                assist our merchants.
              </Grid>
              <Grid item xs={1.7}>
                <Button
                  className="btn--secondary"
                  onClick={() => {
                    setAlertDialogProps({
                      alertTitle: "Update All Terminals?",
                      alertLevel: "info",
                      alertMessages: [
                        "This could render terminals inoperable if they fail to receive the update. Ensure that terminals are on and connected to the internet before proceeding.",
                      ],
                      actionButtons: [
                        {
                          text: "Yes",
                          color: "primary",
                          onclick: () => {
                            updateConfigs();
                          },
                        },
                      ],
                      closeButtonText: "Cancel",
                      closeButtonColor: "neutrals",
                      onCloseButtonClick: () => {
                        setAlertDialogProps({});
                        setAlertDialogOpen(false);
                      },
                    });
                    setAlertDialogOpen(true);
                  }}
                >
                  {" "}
                  Update iQ Pro +
                </Button>
              </Grid>
              <Grid item xs={1.5}>
                <Button
                  className="btn--primary"
                  onClick={() => {
                    setCreate(true);
                  }}
                >
                  {" "}
                  Create New +
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {terminalData?.length > 0 && (
            <Grid item>
              <SearchList
                data={terminalData}
                listClass={"terminal-container"}
                detailsClass={"terminal-container"}
                hasMoreData={terminalData?.length < resultsTotal}
                hideSearch={true}
                cardProps={{
                  getContent: terminalCard,
                  onClick: (index) => {
                    setActiveIndex(index);
                  },
                }}
                activeIndex={activeIndex}
                details={
                  activeTerminalObj && !loadingDetails ? (
                    <div>
                      <TerminalDetails
                        activeTerminalObj={activeTerminalObj}
                        gatewayInfo={gatewayInfo}
                        showError={showError}
                        showSnackbar={showSnackbar}
                        setShowBackdrop={setShowBackdrop}
                        setAlertDialogOpen={setAlertDialogOpen}
                        setAlertDialogProps={setAlertDialogProps}
                        getTerminalData={getTerminalData}
                      />
                    </div>
                  ) : loadingDetails ? (
                    <div
                      style={{
                        width: "fit-content",
                        margin: "auto",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      <Grid
                        container
                        textAlign={"center"}
                        flexDirection="column"
                      >
                        <Grid>
                          <img src={emptyState}></img>
                        </Grid>
                        <Grid item>
                          {
                            "Failed to retrieve terminal details, please refresh to try again"
                          }
                        </Grid>
                      </Grid>
                    </>
                  )
                }
              />
            </Grid>
          )}
          {terminalData?.length === 0 && (
            <Grid item>
              <Grid container textAlign={"center"} flexDirection="column">
                <Grid>
                  {" "}
                  <img src={emptyState}></img>
                </Grid>
                <Grid item>
                  {" "}
                  {terminalData !== null
                    ? "There are currently no terminals configured for this gateway"
                    : "Failed to retrieve terminal data"}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        {terminalTypes && processors && (
          <CreateTerminalModel
            gatewayInfo={gatewayInfo}
            create={create}
            terminalTypes={terminalTypes}
            processors={processors}
            setCreate={setCreate}
            setShowBackdrop={setShowBackdrop}
            showSnackbar={showSnackbar}
            setRefreshData={setRefreshData}
          />
        )}
      </div>
    </>
  );
};

TerminalContainer.propTypes = {
  activeTab: PropTypes.number,
  gatewayInfo: PropTypes.any,
  showSnackbar: PropTypes.func,
  showError: PropTypes.func,
  setShowBackdrop: PropTypes.func,
  setAlertDialogProps: PropTypes.func,
  setAlertDialogOpen: PropTypes.func,
};

export default TerminalContainer;
