import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";
import { getProperty } from "../../utils/objectHelpers";
import { isRequired } from "./formHelpers";
const RegisteredTextField = ({
  name,
  label,
  rules,
  viewOnly,
  showRequired,
  autoComplete,
  defaultValue,
  v2,
  ...props
}) => {
  const {
    register,
    formState: { errors },
    trigger,
  } = useFormContext();
  const error = getProperty(name, errors);

  if (viewOnly) {
    props.variant = "standard";
    props.disabled = true;
    props.InputProps = { ...props.InputProps, disableUnderline: viewOnly };
  }

  if (typeof label === "object" && !showRequired)
    props.className += " label-with-tooltip-required";

  const validateField = (fieldName) => {
    if (rules) {
      trigger(fieldName);
    }
  };
  return (
    <TextField
      required={isRequired(rules)}
      name={name}
      InputLabelProps={{
        shrink: !v2,
        required: showRequired && isRequired(rules),
      }}
      {...register(name, rules)}
      error={error !== undefined}
      helperText={error && error.message}
      defaultValue={defaultValue || ""}
      onBlur={() => {
        validateField(name);
      }}
      autoComplete={autoComplete}
      label={label}
      {...props}
    />
  );
};

export default RegisteredTextField;

RegisteredTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rules: PropTypes.object,
  viewOnly: PropTypes.bool,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  showRequired: PropTypes.bool,
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string,
  v2: PropTypes.bool,
};

RegisteredTextField.defaultProps = {
  viewOnly: false,
  showRequired: true,
  InputProps: {},
  inputProps: {},
  autoComplete: "off",
  v2: false,
};
