import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import EmailIcon from "@mui/icons-material/Email";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinkIcon from "@mui/icons-material/Link";
import MenuItem from "@mui/material/MenuItem";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useContext } from "react";
import { GatewayContext } from "../../contexts/GatewayContext";
import { IncrementalAuthIcon } from "../../assets/icons/Icons";

const infoIcon = (fontSize) => (
  <Icon fontSize={fontSize}>
    <svg viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
      />
    </svg>
  </Icon>
);

const voidIcon = (fontSize) => (
  <Icon fontSize={fontSize}>
    <svg viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"
      />
    </svg>
  </Icon>
);

const captureIcon = (fontSize) => (
  <Icon fontSize={fontSize}>
    <svg viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
      />
    </svg>
  </Icon>
);

const refundIcon = (fontSize) => (
  <Icon fontSize={fontSize}>
    <svg viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M285.363 207.475c0 18.6-9.831 28.431-28.431 28.431h-29.876v-56.14h23.378c28.668 0 34.929 8.773 34.929 27.709zM504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM363.411 360.414c-46.729-84.825-43.299-78.636-44.702-80.98 23.432-15.172 37.945-42.979 37.945-74.486 0-54.244-31.5-89.252-105.498-89.252h-70.667c-13.255 0-24 10.745-24 24V372c0 13.255 10.745 24 24 24h22.567c13.255 0 24-10.745 24-24v-71.663h25.556l44.129 82.937a24.001 24.001 0 0 0 21.188 12.727h24.464c18.261-.001 29.829-19.591 21.018-35.587z"
      />
    </svg>
  </Icon>
);

const vaultIcon = (fontSize) => (
  <Icon fontSize={fontSize}>
    <svg viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z"
      />
    </svg>
  </Icon>
);

const incrementAuthIcon = (fontSize) => (
  <Icon fontSize={fontSize}>
    <IncrementalAuthIcon />
  </Icon>
);

const transactionVoidButtonVisible = (data, userSettings) =>
  (data.status === "PendingSettlement" || data.status === "Authorized") &&
  userSettings?.gatewayUserPermissions["PROCESS_VOID"] &&
  data.transactionSource !== "PhysicalTerminal";

const transactionCaptureButtonVisible = (data, userSettings) =>
  data.status === "Authorized" &&
  data.type === "Authorize" &&
  userSettings?.gatewayUserPermissions["PROCESS_CAPTURE"];
const transactionRefundButtonVisible = (data, userSettings) =>
  (data.status === "Settled" || data.status === "PartiallyRefunded") &&
  (data.type === "Sale" || data.type === "Authorize") &&
  userSettings?.gatewayUserPermissions["PROCESS_REFUND"] &&
  data.transactionSource !== "PhysicalTerminal";
const transactionVaultButtonVisible = (data, userSettings) =>
  !data.customer?.customerId &&
  !data.customerId &&
  data.status !== "Declined" &&
  data.status !== "Unknown" &&
  data.transactionSource !== "PhysicalTerminal" &&
  userSettings?.gatewayUserPermissions["VAULT_CREATE"] &&
  (!data.validOperations ||
    (Array.isArray(data.validOperations) &&
      data.validOperations.includes("VaultCustomer")));
const customerEditButtonVisible = (userSettings) =>
  userSettings?.gatewayUserPermissions["VAULT_UPDATE"];

const customerDeleteButtonVisible = (userSettings) =>
  userSettings?.gatewayUserPermissions["VAULT_DELETE"];

const customerURLButtonVisible = (data) => {
  return data.customerPayableToken?.length > 0;
};
const customerInvoiceVisibile = (userSettings, gatewaySettings) => {
  return (
    userSettings?.gatewayUserPermissions["CREATE_INVOICE"] &&
    gatewaySettings["ALLOW_INVOICING"]
  );
};

const transactionIncreaseAuthButtonVisible = (data) => {
  return (
    Array.isArray(data.validOperations) &&
    data.validOperations.includes("IncrementAuthorization") &&
    data.transactionSource !== "PhysicalTerminal"
  );
};

export const ActionButton = ({
  action,
  data,
  userSettings,
  onClick,
  renderType,
  disabled,
}) => {
  const gatewayInfo = useContext(GatewayContext);
  const gatewaySettings = gatewayInfo.gatewaySettings.reduce((settings, s) => {
    settings[s.code] = s.value.toLowerCase() === "true";
    return settings;
  }, {});

  let actionIcon, actionTitle, actionColor, actionVisible, actionDataCy;

  switch (action) {
    case "transaction_info":
      actionIcon = infoIcon("small");
      actionTitle = "Details";
      actionColor = "secondary";
      actionVisible = true;
      actionDataCy = "details-button";
      break;

    case "transaction_capture":
      actionIcon = captureIcon("small");
      actionTitle = "Capture";
      actionColor = "primary";
      actionVisible = transactionCaptureButtonVisible(data, userSettings);
      actionDataCy = "capture-button";
      break;

    case "transaction_void":
      actionIcon = voidIcon("small");
      actionTitle = "Void";
      actionColor = "error";
      actionVisible = transactionVoidButtonVisible(data, userSettings);
      actionDataCy = "void-button";
      break;

    case "transaction_refund":
      actionIcon = refundIcon("small");
      actionTitle = "Refund";
      actionColor = "secondary";
      actionVisible = transactionRefundButtonVisible(data, userSettings);
      actionDataCy = "refund-button";
      break;

    case "transaction_email":
      actionIcon = <EmailIcon fontSize="medium" />;
      actionTitle = "Email Receipt";
      actionColor = renderType === "icon" ? "warning" : "primary";
      actionVisible = true;
      actionDataCy = "email-button";
      break;

    case "transaction_vault":
      actionIcon = vaultIcon("small");
      actionTitle = "Vault";
      actionColor = "primary";
      actionVisible = transactionVaultButtonVisible(data, userSettings);
      actionDataCy = "vault-button";
      break;

    case "transaction_increment_auth":
      actionIcon = incrementAuthIcon("small");
      actionTitle = "Add Tip Amount";
      actionColor = "primary";
      actionVisible = transactionIncreaseAuthButtonVisible(data);
      actionDataCy = "increment-auth-button";
      break;

    case "transaction_print":
      actionIcon = null;
      actionTitle = "Print View";
      actionColor = "primary";
      actionVisible = true;
      actionDataCy = "print-button";
      break;

    case "customer_edit":
      actionIcon = <EditIcon fontSize="small" />;
      actionTitle = "Edit";
      actionColor = "warning";
      actionVisible = customerEditButtonVisible(userSettings);
      actionDataCy = "edit-button";
      break;
    case "customer_view":
      actionIcon = <VisibilityIcon fontSize="small" />;
      actionTitle = "View";
      actionColor = "primary";
      actionVisible = true;
      actionDataCy = "view-button";
      break;

    case "customer_charge":
      actionIcon = <PointOfSaleIcon fontSize="small" />;
      actionTitle = "Charge";
      actionColor = "primary";
      actionVisible = true;
      actionDataCy = "charge-button";
      break;

    case "customer_delete":
      actionIcon = <DeleteIcon fontSize="small" />;
      actionTitle = "Delete";
      actionColor = "error";
      actionVisible = customerDeleteButtonVisible(userSettings);
      actionDataCy = "delete-button";
      break;

    case "customer_URL":
      actionIcon = <LinkIcon />;
      actionTitle = "Copy Customer Payables URL";
      actionColor = "primary";
      actionVisible = customerURLButtonVisible(data);
      actionDataCy = "copy-url-button";
      break;
    case "customer_invoice":
      actionIcon = <ReceiptIcon />;
      actionTitle = "Create Customer Invoice";
      actionColor = "primary";
      actionVisible = customerInvoiceVisibile(userSettings, gatewaySettings);
      actionDataCy = "customer-invoice-button";
      break;
    default:
      break;
  }

  if (actionVisible && renderType === "icon")
    return (
      <IconButton
        title={actionTitle}
        variant="container"
        sx={{ padding: 0.5 }}
        onClick={onClick}
        color={actionColor}
        fontSize="small"
        data-cy={actionDataCy}
      >
        {actionIcon}
      </IconButton>
    );
  if (actionVisible && renderType === "button")
    return (
      <Button
        variant="contained"
        size="small"
        color={actionColor}
        sx={{ margin: 0.5 }}
        disabled={disabled}
        onClick={onClick}
        data-cy={actionDataCy}
      >
        {actionTitle}
      </Button>
    );
  if (actionVisible && renderType === "menu")
    return (
      <MenuItem onClick={onClick}>
        <IconButton
          title={actionTitle}
          variant="container"
          sx={{ padding: 0.5 }}
          onClick={onClick}
          color={actionColor}
          fontSize="small"
          data-cy={actionDataCy}
        >
          {actionIcon}
        </IconButton>
        <span style={{ marginLeft: "10px" }}>{actionTitle}</span>
      </MenuItem>
    );
  else return <></>;
};

ActionButton.defaultProps = {
  renderType: "icon",
  disabled: false,
};

ActionButton.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  userSettings: PropTypes.object,
  onClick: PropTypes.func,
  renderType: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ActionButton;
